import React from 'react';
import { Table } from 'semantic-ui-react';
import WeightItem from './WeightItem';

const WeightList = ({
  weights,
  onEditWeight,
  onRemoveWeight,
}) => (
  <Table celled>
  <Table.Header>
  <Table.Row>
  <Table.HeaderCell>Tag</Table.HeaderCell>
  <Table.HeaderCell>Weight</Table.HeaderCell>
  <Table.HeaderCell>Date</Table.HeaderCell>
  <Table.HeaderCell>Notes</Table.HeaderCell>
  </Table.Row>
  </Table.Header>
  <Table.Body>
  {weights.map(weight => (
    <WeightItem
    key={weight.uid}
    weight={weight}
    onEditWeight={onEditWeight}
    onRemoveWeight={onRemoveWeight}
    />
    ))}
    </Table.Body>
    </Table>
    
    );
    
    export default WeightList;
    