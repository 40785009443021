/* eslint-disable no-unused-vars */
import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import {Form,Button,Header,Grid,Image,Segment,Message, Container  } from 'semantic-ui-react';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import * as ROUTES from 'constants/routes';
import { FirebaseContext } from "../../Firebase";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};
const SignInPage = () => (
  <Container text style={{ marginTop: '7em' , opacity: 1 }}>
  <Segment >
  <Grid textAlign='center' style={{ height: '50vh' }} verticalAlign='middle'>
  <Grid.Column style={{ maxWidth: 450 }}>
  <SignInForm/>
  <PasswordForgetLink />
  <SignUpLink />
  </Grid.Column>
  </Grid>
  </Segment>
  </Container>
  );

function SignInForm() {
  const [{ email, password, error }, setSignInFormState] = React.useState(
    INITIAL_STATE
  );
  const firebase = React.useContext(FirebaseContext);
  const history = useHistory();

  const isInvalid = password === "" || email === "";

  const onChange = event => {
    const { name, value } = event.target;
    setSignInFormState(prev => ({ ...prev, [name]: value }));
  };

  const onSubmit = event => {
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setSignInFormState(INITIAL_STATE);
        history.push(ROUTES.HOME);
      })
      .catch(error => {
        setSignInFormState(prev => ({ ...prev, error: error }));
      });

    event.preventDefault();
  };

  return (
    <Form onSubmit={onSubmit}>
         <Segment stacked>
          <Header color='blue'>
          <Image src='/logo512.png' />Log in to your account.
          </Header>
      <Form.Input
        name="email"
        fluid
        icon='user'
        iconPosition='left'
        value={email}
        onChange={onChange}
        type="text"
        placeholder="Email Address"
      />
      <Form.Input
      fluid
      icon='lock'
      iconPosition='left'
        name="password"
        value={password}
        onChange={onChange}
        type="password"
        placeholder="Password"
      />
      <Form.Field>
      <Button disabled={isInvalid} type="submit">
        Sign In
      </Button>
      </Form.Field>    
            {error && <Message>{error.message}</Message>}
          </Segment>  
        </Form>
  );
}

export default SignInPage;
export { SignInForm };