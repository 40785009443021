import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import 'semantic-ui-less/semantic.less';
import { Header, Image, Segment, Container,Grid,Button,Input,Form } from 'semantic-ui-react';
import { withFirebase } from '../../Firebase';
import * as ROUTES from 'constants/routes';
import * as ROLES from 'constants/roles'
const SignUpPage = () => (
  <Container text style={{ marginTop: '7em' , opacity: 1 }}>
  <Grid textAlign='center' style={{ height: '50vh' }} verticalAlign='middle'>
  <Grid.Column style={{ maxWidth: 450 }}>
  <SignUpForm />
  </Grid.Column>
  </Grid>
  </Container>
);

const INITIAL_STATE = {
  fullname: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  isAdmin: false,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {...INITIAL_STATE };
  }

  onSubmit = event => {
    const { fullname, email, passwordOne, isAdmin } = this.state;
    const roles = [];
    if (isAdmin) {
      roles.push(ROLES.ADMIN);
    }
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        this.props.firebase
          .user(authUser.user.uid)
          .set({
            fullname,
            email,
            roles,
            isAdmin
          })
          .then(() => {
            this.setState({...INITIAL_STATE });
            this.props.history.push(ROUTES.HOME);
          })
          .catch(error => {
            this.setState({ error });
          });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      fullname,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      fullname === '';

    return (
      <Form onSubmit={this.onSubmit}>
         <Segment stacked>
         <Header color='blue'>
          <Image src='/logo512.png' />Create your account.
          </Header>
         
        <Form.Field>
          <Input
            name="fullname"
            value={fullname}
            onChange={this.onChange}
            type="text"
            placeholder="Full Name"
          />
        </Form.Field>
        <Form.Field>
        <Input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        </Form.Field>
        <Form.Field>
        <Input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        </Form.Field>
        <Form.Field>
        <Input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm Password"
        />
        </Form.Field>
        <Form.Field>
        <Button disabled={isInvalid} type="submit" color='blue'>
          Sign Up
        </Button>
        </Form.Field>

        {error && <p>{error.message}</p>}
        </Segment>
      </Form>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);
const SignUpForm = withRouter(withFirebase(SignUpFormBase));
export default SignUpPage;
export { SignUpForm, SignUpLink };