import React, { Component } from 'react';
import { Card } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';

class CattleItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      cattle: null,
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (this.state.cattle) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .beef(this.props.match.params.id)
      .on('value', snapshot => {
        this.setState({
          cattle: snapshot.val(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.cattle(this.props.match.params.id).off();
  }

  render() {
    const { cattle, loading } = this.state;

    return (
      <Card>
        <Card.Content>
          <Card.Header>Beef {cattle.tag}</Card.Header>
          <Card.Meta>
            {loading && <div>Loading ...</div>}
          </Card.Meta>
          </Card.Content>
        {cattle && (
          <Card.Content extra>
            <span>
              <strong>Tag:</strong> {cattle.tag}<br/>
            </span>
            <span>
              <strong>Birthdate</strong> {cattle.birth}<br/>
            </span>
            <span>
              <strong>Delivered:</strong> {cattle.delivered}<br/>
            </span>
            <span>
              <strong>Slaughtered:</strong> {cattle.slaughtered}<br/>
            </span>
            
            <span>
              <button
                type="button"
                onClick={this.onUpdateCattleInfo}
              >
                Update
              </button>
            </span>
            ({this.props.match.params.id})
          </Card.Content>
        )}
       

      </Card>
    );
  }
}

export default withFirebase(CattleItem);
