/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { Button, Menu, Container,Divider } from 'semantic-ui-react';
import  { WeightList } from '../Weights';


class AdminFunctions extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            badAmount: "",
            purged: "false",
        };
    }
 

    purgeBadWeights (badAmount) {
        this.setState({ loading: true});
        this.props.firebase
        .weights()
        .orderByChild("amount")
        .equalTo(badAmount)
        .on('value', snapshot => {
            const weightObject = snapshot.val();
            if (weightObject) {
                Object.keys(weightObject).map(key => (
                    this.props.firebase.weight(key).remove()
                ));
            };
        });
    }

    showBadWeights = badAmount => {
        this.setState({ weights: null, loading: true });     
        this.props.firebase
        .weights()
        .orderByChild('amount')
        .equalTo(badAmount)
        .on('value', snapshot => {
            const weightObject = snapshot.val();
            
            if (weightObject) {
                const weightList = Object.keys(weightObject).map(key => ({
                    ...weightObject[key],
                    uid: key,
                }));
                
                this.setState({
                    weights: weightList,
                    loading: false,
                });
            } else {
                this.setState({ weights: null, loading: false });
            }
        });
    }
  
    componentWillUnmount() {
        this.props.firebase.weights().off();
      }

    render () {
        const { weights } = this.state;
        
        return(
            <Container text>
            <Menu>
            <Menu.Item>
            <Button onClick={(e) => this.showBadWeights("")}>Show Bad Weights</Button>
            </Menu.Item>
            <Menu.Item>
            <Button onClick={(e) => this.purgeBadWeights("")}>Purge Bad Weights</Button>
            </Menu.Item>
            </Menu>
            {weights && (
                <div>
                <Divider horizontal color='blue'>Recent Weights</Divider>
                <WeightList
                
                weights={weights}
                onEditWeight={this.onEditWeight}
                onRemoveWeight={this.onRemoveWeight}
                />
                </div>
                )}
            </Container>
        );
    }
};
export default withFirebase(AdminFunctions);