import React, { Component } from "react";
import { Form, Button, Modal, Header } from "semantic-ui-react";

class EditWeightItem extends Component {

  onChangeAmount = event => {
    this.setState({ amount: event.target.value });
  };

  onChangeDate = event => {
    this.setState({ date: event.target.value });
  };

  onModalChangeTag = event => {
    this.props.onChangeTag(event);
  };

  onChangeNote = event => {
    this.setState({ note: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditWeight(this.props.weight);
  };

  onDeleteWeightItem = () => {
    this.props.onRemoveWeight(this.props.weight.uid)
  };

  state = { modalOpen: false };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  render() {
    const { weight } = this.props;
    return (
      <Modal
        trigger={<Button onClick={this.handleOpen}>Edit</Button>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
            <Header>Edit Weight For {weight.tag} {weight.uid}</Header>
            <Modal.Content>
              <Form>
                <Form.Input
                  type="date"
                  value={weight.date}
                  onChange={this.onChangeDate}
                />
                <Form.Input
                  autoFocus
                  size="massive"
                  placeholder="Tag"
                  type="number"
                  name="tag"
                  value={weight.tag}
                  onChange={this.onModalChangeTag}
                />
                <Form.Input
                  size="massive"
                  placeholder="Weight"
                  type="number"
                  value={weight.amount}
                  onChange={this.onChangeAmount}
                />
                <Form.Input
                  placeholder="Notes"
                  type="text"
                  value={weight.note}
                  onChange={this.onChangeNote}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color="green" onClick={this.onSaveEditText} inverted>
                Save
              </Button>
              <Button color="red" onClick={this.onDeleteWeightItem} inverted>
                Delete
              </Button>
              <Button color="yellow" onClick={this.handleClose} inverted>
                Cancel
              </Button>
            </Modal.Actions>
       

      </Modal>
    );
  }
}

export default EditWeightItem;
