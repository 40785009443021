/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import { Message, Segment} from 'semantic-ui-react'
import { withFirebase } from '../Firebase';
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesReferenceLine} from 'react-sparklines';
import { Line } from 'react-chartjs-2';



class WeightHistory extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      tag: this.props.tag,
      tagWeights: null,
      loading: true,
    };
  }
  
  componentDidMount(){
    this.setState({ loading: true });
    this.getTagWeight();
  }
  
  componentWillUnmount() {
    this.props.firebase.weights().off();
  }
  
  getTagWeight () {
    this.props.firebase
    .weights().orderByChild('tag')
    .equalTo(this.state.tag.toString())
    .once('value').then( snapshot => {
      const weightObject = snapshot.val();
      if (weightObject) {
        const weightList = Object.keys(weightObject).map(key => ({
          ...weightObject[key],
          uid: key,
        }));
        
        this.setState({
          tagWeights: weightList.sort((a, b) =>
          new Date(a.date) > new Date(b.date) ? 1 : -1
        ),
          loading: false,
          results: "Found some!",
          data: true
        });
      } else {
        this.setState({ results: "None found", tagWeights: null, loading: false, error: true });
      }
    });
  }
  renderLoading() {
    return <Message>Retrieving weights</Message>;
  }
  
  renderError () {
    return <Message>{this.state.results}</Message>;
  }
  
  renderData () {
    const {tag,tagWeights,loading} = this.state;
    
    let sparkData = tagWeights.map(a => {
      return Number(a.amount);
    });

    return (
      <Segment>
       {!loading && (
        <Sparklines data={sparkData} max={1800} min={200}>
          <SparklinesLine />
          <SparklinesReferenceLine type='custom' value={30} />
        </Sparklines>
        )}
        
        </Segment>
    );
  }
      
  render () {
    
    if (this.state.loading) {
      return this.renderLoading() ; 
    } else if (this.state.data) {
      return this.renderData();
    } else if (this.state.error){
      return this.renderError();
    }
    
  }
  
};

export default withFirebase(WeightHistory);