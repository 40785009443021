import React, { Component } from "react";
import { Statistic, Table } from "semantic-ui-react";
import EditWeightItem from "./EditWeightItem";

class WeightItem extends Component {
  
  render() {
    const { weight,
      onEditWeight,
      onRemoveWeight,
      onChangeTag,
    } = this.props;

    return (
      <Table.Row key={weight.uid}>
        <Table.Cell>{weight.tag}</Table.Cell>
        <Table.Cell>
          <Statistic size="mini">
            <Statistic.Value>{weight.amount}</Statistic.Value>
            <Statistic.Label>lbs</Statistic.Label>
          </Statistic>
        </Table.Cell>
        <Table.Cell>{weight.date}</Table.Cell>
        <Table.Cell>
          {weight.notes}
          <EditWeightItem
            wid={weight.uid}
            weight={weight}
            onEditWeight={onEditWeight}
            onRemoveWeight={onRemoveWeight}
            onChangeTag={onChangeTag}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default WeightItem;
