/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { withFirebase } from "components/Firebase";
import { Button,Container, Form, Label, Segment, Divider } from "semantic-ui-react";
import { AuthUserContext } from "components/Session";
import CattleList  from "./CattleList";


class Cattle extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      tag: "",
      deliveryDate: new Date().toISOString().split("T", 1),
      note: "",
      birthDate: "",
      limit: 30,
    };
  }

componentDidMount(){
    this.onListenForCattle();
}
  componentWillUnmount() {
    this.props.firebase.cattle().off();
  }

  onChangeDeliveryDate = event => {
    this.setState({ deliveryDate: event.target.value });
  };

  onChangeBirthDate = event => {
    this.setState({ BirthDate: event.target.value });
  };

  onChangeTag = event => {
    this.setState({ tag: event.target.value });
  };

  onChangeNote = event => {
    this.setState({ note: event.target.value });
  };

  onCreateTag = (event, authUser) => {
    this.props.firebase.cattle().push({
      tag: this.state.tag,
      deliveryDate: this.state.deliveryDate,
      birthDate: this.state.birthDate,
      note: this.state.note,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP
    });
    this.setState({ tag: "", note: "" });
    event.preventDefault();
  };

  onEditTag = (tag, note, deliveryDate, birthDate, authUser) => {
    const { uid, ...tagSnapshot } = tag;
    this.props.firebase.cattle(tag.uid).set({
      ...tagSnapshot,
      tag,
      deliveryDate,
      birthDate,
      note,
      userId: authUser.uid,
      editedAt: this.props.firebase.serverValue.TIMESTAMP
    });
  };

  onRemoveTag = uid => {
    this.props.firebase.tag(uid).remove();
  };

  onListenForCattle = () => {
    this.setState({ loading: true });

    this.props.firebase
      .cattle()
      .orderByChild("deliveryDate")
      .limitToLast(this.state.limit)
      .on("value", snapshot => {
        const cattleObject = snapshot.val();

        if (cattleObject) {
          const cattleList = Object.keys(cattleObject).map(key => ({
            ...cattleObject[key],
            uid: key
          }));

          this.setState({
            cattle: cattleList.sort((a, b) =>
              new Date(a.deliveryDate) < new Date(b.deliverDate) ? 1 : -1
            ),
            loading: false
          });
        } else {
          this.setState({ cattle: null, loading: false });
        }
      });
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 10 }),
      this.onListenForCattle
    );
  };

  render() {
    const { tag, cattle, deliveryDate, birthDate, note, loading} = this.state;
    const isInvalid = tag === '' ;

    return (
      <Container text style={{ marginTop: "4em" }}>
        <AuthUserContext.Consumer>
          {authUser => (
            <Segment stacked>
              <Form onSubmit={event => this.onCreateTag(event, authUser)}>
                <Form.Field>
                  <Label>Delivery Date</Label>
                  <Form.Input
                    type="date"
                    value={deliveryDate}
                    onChange={this.onChangeDeliveryDate}
                  />
                </Form.Field>
                <Form.Field>
                  <Label>Birth Date</Label>
                  <Form.Input
                    type="date"
                    value={birthDate}
                    onChange={this.onChangeBirthDate}
                    clearable="true"
                  />
                </Form.Field>
                <Form.Input
                  autoFocus
                  size="massive"
                  placeholder="Tag"
                  type="number"
                  value={tag}
                  onChange={this.onChangeTag}
                />
                <Form.Field>
                  <Button type="submit" color="blue" disabled={isInvalid}>
                    Search Tag
                  </Button>
                </Form.Field>
              </Form>
              {loading && <div>Loading ...</div>}

              {!cattle && <div>There are no cattle</div>}

              {cattle && (
                <Container>
                  <Divider horizontal fgcolor="blue">
                    Recent tags entered
                  </Divider>
                  <CattleList
                    authUser={authUser}
                    cattle={cattle}
                    onEditTag={this.onEditTag}
                    onRemoveTag={this.onEditTag}
                  />
                </Container>
              )}
              {!loading && cattle && (
                <Button type="button" onClick={this.onNextPage} color="blue">
                  More
                </Button>
              )}
            
            </Segment>
          )}
        </AuthUserContext.Consumer>
      </Container>
    );
  }
}

export default withFirebase(Cattle);
