import React, { Component } from 'react';
import { Container, Table, Message } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';
import WeightHistory from "components/Weights/WeightHistory";


class CattleList extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: false,
      cattle: [],
      limit: 30,
    };
  }
  
  componentDidMount() {
    this.setState({ loading: true });
    
    this.props.firebase.cattle().limitToLast(this.state.limit).on('value', snapshot => {
      const cattleObject = snapshot.val();
      if (cattleObject) {
        const cattleList = Object.keys(cattleObject).map(key => ({
          ...cattleObject[key],
          uid: key,
        }));
        
        this.setState({
          cattle: cattleList,
          loading: false,
        });
      } else {
        this.setState({ cattle: null, loading: false });
      }
    });
  }
  
  componentWillUnmount() {
    this.props.firebase.cattle().off();
  }
  
  render() {
    const { cattle, loading } = this.state;
  
   
    return (
      <Container>
      {cattle && (
      <Container>
        {loading && <div>Loading ...</div>}
        <Table celled>
          <Table.Header>
          <Table.Row>
          <Table.HeaderCell>Tag</Table.HeaderCell>
          <Table.HeaderCell>Delivered</Table.HeaderCell>
          <Table.HeaderCell>Details</Table.HeaderCell>
          </Table.Row>
          </Table.Header>
          <Table.Body>
          {cattle.map(cattle => (
            <Table.Row key={cattle.uid}>
              <Table.Cell>
                {cattle.tag}
              </Table.Cell>
              <Table.Cell>
              {cattle.deliveryDate}
              </Table.Cell>
              <Table.Cell>
                <WeightHistory tag={cattle.tag} />
              </Table.Cell>
            </Table.Row>
            ))}
        </Table.Body>
        </Table>
        </Container>
        )}

        {!cattle && <Message>There are no cattle</Message>}
        </Container>
      )}
}
  
export default withFirebase(CattleList);
    