/* eslint-disable no-unused-vars */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization} from '../Session';
import CattleItem from './CattleItem';
import Cattle from './Cattle';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import { Segment } from 'semantic-ui-react';

const CattlePage = () => (
  <Segment style={{ marginTop: '4em' }}>
    <h1>Cattle In The System</h1>
    <Cattle/>

    <Switch>
      <Route exact path={ROUTES.BEEF} component={CattleItem} />
    </Switch>
  </Segment>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(CattlePage);