/* eslint-disable no-unused-vars */
import React from 'react';
import { Button } from 'semantic-ui-react';
import { FirebaseContext } from '../../Firebase';

function SignOutButton() {
  const firebase = React.useContext(FirebaseContext);

  return (
      <Button type="button" onClick={firebase.doSignOut}>
          Sign Out
      </Button>
  )
}
export default SignOutButton;