/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { Menu, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import SignOutButton from '../Users/SignOut';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
);

const NavigationAuth = () => (
  <Menu fixed='top' inverted>
    <Menu.Item>
      <Link to={ROUTES.HOME}>
        <Image size='mini' src='/logo512w.png' />
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={ROUTES.CATTLE}>Manage Cattle</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={ROUTES.WEIGHTS}>Enter Weights</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={ROUTES.ACCOUNT}>Account</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={ROUTES.ADMIN}>Admin</Link>
    </Menu.Item>
    <Menu.Item>
      <SignOutButton />
    </Menu.Item>
  </Menu>
);

const NavigationNonAuth = () => (
  <Menu fixed='top' inverted>
    <Menu.Item>
      <Link to={ROUTES.LANDING}>
        <Image size='mini' src='/logo512w.png' />
      </Link>
      </Menu.Item>
    <Menu.Item>
      <Link to={ROUTES.SIGN_IN}>Sign In</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={ROUTES.SIGN_UP}>Create Account</Link>
    </Menu.Item>
  </Menu>
);

export default Navigation;
