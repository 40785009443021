import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../Users/SignUp';
import SignInPage from '../Users/SignIn';
import PasswordForgetPage from '../Users/PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Users/Account';
import AdminPage from '../Admin';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { CattlePage } from '../Cattle';
import { Weights } from '../Weights';


const App = () => (
  <Router>
    <div>
      <Navigation />
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route path={ROUTES.HOME} component={HomePage} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route path={ROUTES.ADMIN} component={AdminPage} />
      <Route path={ROUTES.CATTLE} component={CattlePage} />
      <Route path={ROUTES.WEIGHTS} component={Weights} />
    </div>
  </Router>
);

export default withAuthentication(App);