import React from 'react';
import { withAuthorization } from '../Session';
import { Container } from 'semantic-ui-react';

const HomePage = () => (
 <Container></Container>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);