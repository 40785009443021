import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';


var config = {
    apiKey: "AIzaSyBLzKxPxgMGM5cJhyNdBIqjyWiszHumiz8",
    authDomain: "floof-rodeo-253814.firebaseapp.com",
    databaseURL: "https://floof-rodeo-253814.firebaseio.com",
    projectId: "floof-rodeo-253814",
    storageBucket: "floof-rodeo-253814.appspot.com",
    messagingSenderId: "609762815079",
    appId: "1:609762815079:web:ec7d1071dc8722a6e938c2",
    measurementId: "G-JBWED3SLHG"
  };

  /* TODO:
  const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};
*/
  class Firebase {
    constructor() {
      app.initializeApp(config);
  
      /* Helper */
      this.serverValue = app.database.ServerValue;
      this.emailAuthProvider = app.auth.EmailAuthProvider; 

      /* Firebase APIs */ 
      this.auth = app.auth();
      this.db = app.database();
      this.firestore = app.firestore();
  
      /* Social Sign In Method Provider */
      this.googleProvider = new app.auth.GoogleAuthProvider();
    }
  
    // *** Auth API ***
  
    doCreateUserWithEmailAndPassword(email, password){
     this.auth.createUserWithEmailAndPassword(email, password);
    } 
  
    doSignInWithEmailAndPassword(email, password) {
      return this.auth.signInWithEmailAndPassword(email, password);
    }

    doSignInWithGoogle(){
      this.auth.signInWithPopup(this.googleProvider);
    }
  
    doSignOut = () => this.auth.signOut();
  
    doPasswordReset(email){
       this.auth.sendPasswordResetEmail(email);
    }
  
    doSendEmailVerification(){
      return this.auth.currentUser.sendEmailVerification({
        url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
      });
    }
  
    doPasswordUpdate(password){
      return this.auth.currentUser.updatePassword(password);
    }
    
    // *** Merge Auth and DB User API *** //
  
    onAuthUserListener(next, fallback){
      return this.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          this.user(authUser.uid)
            .once('value')
            .then(snapshot => {
              const dbUser = snapshot.val();
  
              // default empty roles
              if (!dbUser.roles) {
                dbUser.roles = {};
              }
  
              // merge auth and db user
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbUser
              };
  
              next(authUser);
            });
        } else {
          fallback();
        }
      });
    }
  
    // *** User API ***
  
    user = uid => this.db.ref(`users/${uid}`);
  
    users = () => this.db.ref('users');
  
    // *** Weight API ***
  
    weight = uid => this.db.ref(`weights/${uid}`);
  
    weights = () => this.db.ref('weights');

    // *** Cattle API ***
    
    beef = uid => this.db.ref(`cattle/${uid}`);

    cattle = () => this.db.ref(`cattle`);
  }
  
export default Firebase;