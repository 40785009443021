/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Divider, Container, Button, Form, Segment } from "semantic-ui-react";
import { AuthUserContext } from "../Session";
import { withAuthorization} from '../Session';
import { withFirebase } from '../Firebase';
import WeightList from "./WeightList";

class Weights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tag: "",
      date: new Date().toISOString().split("T", 1),
      amount: "",
      loading: false,
      note: "",
      weights: [],
      limit: 20
    };
  }

  onListenForWeights = () => {
    this.setState({ loading: true });

    this.props.firebase
      .weights()
      .orderByChild("date")
      .limitToLast(this.state.limit)
      .on("value", snapshot => {
        const weightObject = snapshot.val();
        if (weightObject) {
          const weightList = Object.keys(weightObject).map(key => ({
            ...weightObject[key],
            uid: key
          }));

          this.setState({
            weights: weightList.sort((a, b) =>
              new Date(a.date) < new Date(b.date) ? 1 : -1
            ),
            loading: false
          });
        } else {
          this.setState({ weights: null, loading: false });
        }
      });
  };

  componentDidMount() {
    this.onListenForWeights();
  }

  componentWillUnmount() {
    this.props.firebase.weights().off();
  }

  onChangeAmount = event => {
    this.setState({ amount: event.target.value });
  };

  onChangeDate = event => {
    this.setState({ date: event.target.value });
  };

  onChangeTag = event => {
    this.setState({ tag: event.target.value });
  };

  onChangeNote = event => {
    this.setState({ note: event.target.value });
  };

  onCreateWeight = (event, authUser) => {
    this.props.firebase.weights().push({
      tag: this.state.tag,
      date: this.state.date,
      amount: this.state.amount,
      note: this.state.note,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP
    });

    this.setState({ tag: "", amount: "",note: "" });

    event.preventDefault();
  };

  onEditWeight = (weight) => {
    const { uid, ...weightSnapshot } = weight;
    this.props.firebase.weights(weight.uid).set({
      ...weightSnapshot,
      editedAt: this.props.firebase.serverValue.TIMESTAMP
    });
  };

  onRemoveWeight = uid => {
    this.props.firebase.weight(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 10 }),
      this.onListenForWeights
    );
  };

  render() {
    const { tag, date, amount, weights, note, loading } = this.state;

    return (
      <Container text style={{ marginTop: "4em" }}>
        <AuthUserContext.Consumer>
          {authUser => (
            <Segment stacked>
              <Form onSubmit={event => this.onCreateWeight(event, authUser)}>
                <Form.Input
                  type="date"
                  value={date}
                  onChange={this.onChangeDate}
                />
                <Form.Input
                  autoFocus
                  size="massive"
                  placeholder="Tag"
                  type="number"
                  value={tag}
                  onChange={this.onChangeTag}
                />
                <Form.Input
                  size="massive"
                  placeholder="Weight"
                  type="number"
                  value={amount}
                  onChange={this.onChangeAmount}
                />
                <Form.Input
                  placeholder="Notes"
                  type="text"
                  value={note}
                  onChange={this.onChangeNote}
                />
                <Form.Field>
                  <Button type="submit" color="blue">
                    Add Weight
                  </Button>
                </Form.Field>
              </Form>

              {loading && <div>Loading ...</div>}

              {weights && (
                <div>
                  <Divider horizontal fgcolor="blue">
                    Recent Weights
                  </Divider>
                  <WeightList
                    authUser={authUser}
                    weights={weights}
                    onEditWeight={this.onEditWeight}
                    onRemoveWeight={this.onRemoveWeight}
                    onChangeTag={this.onChangeTag}
                  />
                </div>
              )}

              {!weights && <div>There are no weights ...</div>}

              {!loading && weights && (
                <Button type="button" onClick={this.onNextPage} color="teal">
                  More
                </Button>
              )}
            </Segment>
          )}
        </AuthUserContext.Consumer>
      </Container>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withFirebase(Weights));

